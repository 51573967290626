<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <div class="d-flex my-2">
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="mx-1" color="info" @click="getMembers()"><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="success" @click="openAddNumber()"><v-icon>mdi-account-multiple-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Policy</span>
        </v-tooltip>
      </div>
      <template>
        <v-data-table :headers="headers" single-expand :expanded.sync="expanded" show-expand item-key="uuid"
          :items="members" :loading="loading" :items-per-page="10" class="elevation-1">
          <template v-slot:[`item.company`]="{ item }">
            {{ item.company ? item.company.name : '-' }}
          </template>
          <template v-slot:[`item.companyState`]="{ item }">
            {{ item.companyState ? item.companyState.name : '-' }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" icon @click="toEdit(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              </template>
              <span>Edit Policy</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="error" icon @click="toDelete(item)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <span>Delete Policy</span>
            </v-tooltip>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <label for="first name">First Name: </label>
                    <br />
                    <strong>{{ item.firstName }}</strong>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <label for="middle name">Middle Name: </label>
                    <br />
                    <strong>{{ item.midleName ? item.midleName : "-" }}</strong>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="last name">Last Name: </label>
                    <br />
                    <strong>{{ item.lastName }}</strong>
                  </v-col>

                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <label for="dob">Date of Birth: </label>
                    <br />
                    <strong>{{ getDate(item.birthDate) }}</strong>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="dob">Phone </label>
                    <br />
                    <strong>
                      <template v-if="item.homePhone">
                        <div class="d-flex">
                          <div class="cursor-pointer" @click="makeCallV(item.homePhone)">
                            {{ item.homePhone }}
                          </div>
                          <div class="mx-1">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon @click="copyPhone(item.homePhone)" v-on="on" small
                                  color="info">mdi-content-copy</v-icon>
                              </template>
                              <span>Copy Phone</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </template>
                      <template v-else> - </template>
                    </strong>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="gender">Gender: </label>
                    <br />
                    <strong>{{ item.isMale ? "Male" : "Female" }}</strong>
                  </v-col>

                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <label for="relation">Number ID: </label>
                    <br />
                    <strong>{{ item.idNumber ? item.idNumber : "-" }}</strong>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <label for="ls">Legal Status: </label>
                    <br />
                    <strong> {{ getLegal(item.legalStatus) }}</strong>
                  </v-col>


                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <label for="relation">Company: </label>
                    <br />
                    <strong>{{
                      item.company ? item.company.name : "-"
                    }}</strong>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="relation">Company State: </label>
                    <br />
                    <strong>{{
                      item.companyState ? item.companyState.name : '-'
                    }}</strong>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div>
                      Binder pay
                    </div>
                    <strong>
                      {{ item.binderDay ? "Yes" : "No" }}
                    </strong>
                  </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <label for="gender">Policy Number: </label>
                    <br />
                    <div class="d-flex">
                      <strong>{{
                        item.polizeNumber ? item.polizeNumber : "-"
                      }}</strong>
                      <div class="px-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon @click="copyPolizeNumber(item.polizeNumber)" v-on="on" small
                              color="info">mdi-content-copy</v-icon>
                          </template>
                          <span>Copy Policy Number</span>
                        </v-tooltip>
                      </div>
                    </div>

                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="gender">Effective Date: </label>
                    <br />
                    <template v-if="item.effectiveDate">
                      <strong>{{
                        efectiveDate(item.effectiveDate)
                      }}</strong>
                    </template>
                    <template v-else>-</template>

                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="gender">Metal Plan: </label>
                    <br />
                    <div class="text-body-1 font-weight-bold" v-bind:style="{ color: colorPolicy(item) }">
                      {{ item.policyPlan ? item.policyPlan : "-" }}
                    </div>
                  </v-col>


                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <label for="gender">Premium: </label>
                    <br />
                    <strong>{{
                      !item.premium ? 0 : item.premium | currency
                    }}</strong>
                  </v-col>
                  <v-col cols="12" sm="4"  @click="gotoMembers(item)"
                  class="cursor-pointer">
                    <label for="gender">Members: </label>
                    <br />
                    <strong>{{
                      showMembers(item)
                    }}</strong>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
        <v-dialog           
           v-model="dialogMembers"
          transition="fab-transition"
           max-width="1200"
         >
         <v-card>
           <v-toolbar dark color="primary"><v-toolbar-title>Members</v-toolbar-title>
           <v-spacer></v-spacer>
            <template v-if="!editing">
              <v-btn outlined small @click="toEditing"> <v-icon small>mdi-pencil</v-icon> Edit</v-btn>
            </template>
            <template v-else>
              <div class="valuemembers">
                <v-text-field
              v-model="valuemembers"             
              outlined            
              dark
              dense
              type="number"
            
            ></v-text-field>
              </div>           
              <v-btn outlined small :loading="loading" class="mr-1" :disabled="valuemembers<=0||loading" @click="confirmEditMembers" > <v-icon>mdi-check</v-icon>Confirm</v-btn>
              <v-btn outlined small  @click="cancelEditing"><v-icon>mdi-close</v-icon> Cancel</v-btn>
             
            </template>
           </v-toolbar>
           <template v-if="itemMembers">
             <v-simple-table>
               <template v-slot:default>
                 <thead>
                   <tr>
                    
                     <th class="text-center" v-for="month in monthsShow" :key="month">{{ month }}</th>
                   
                   </tr>
                 </thead>
                 <tbody>
                   <tr >                      
                     <td  class="text-center" v-for="(item,index) in itemMembers" :key="index">{{ item }}</td>                      
                   </tr>
                 </tbody>
               </template>
             </v-simple-table>
           </template> 
         </v-card>          
         </v-dialog>
        <v-dialog v-model="deleteMember.dialog" persistent :overlay="false" max-width="350px"
          transition="dialog-transition">
          <v-card>
            <v-toolbar color="error">
              <h3 class="white--text">
                Delete Member:
                {{
                  deleteMember.item
                    ? deleteMember.item.firstName +
                    " " +
                    deleteMember.item.lastName
                    : ""
                }}
              </h3>
            </v-toolbar>
            <v-card-text class="text-center mt-4">
              <span class="body-1">Are you sure?</span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelAll">Cancel</v-btn>
              <v-btn :loading="deleteMember.loading" :disabled="deleteMember.loading" color="error" text
                @click="confirmDelete">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import rules from "@/components/account/rules";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo, notifySuccess } from "@/components/Notification";
import moment from 'moment';
import months from '@/utils/enums/months'
import { funtionsPhone, utilMixin } from '@/utils/mixins';
export default {
  name: "patient-members",
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      rules,
      members: [],
      dialogMembers:false,
      itemMembers:null,
      expanded: [],     
      headers1: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullname",
        },
        {
          text: "Policy Number",
          align: "start",
          sortable: false,
          value: "polizeNumber",
        },
      ],
      deleteMember: {
        dialog: false,
        loading: false,
        item: null,
      },
      loading: false,
      valuemembers:1,
      editing:false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: "Last name",
          align: "start",
          sortable: false,
          value: "lastName",
        },
        {
          text: "Company",
          align: "center",
          sortable: false,
          value: "company",
        },
        {
          text: "Company State",
          align: "center",
          sortable: false,
          value: "companyState",
        },

        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    ...mapGetters(["isSuper", "isAdmin"]),
    monthsShow(){
      const m = months;
      return m.map(m=>{
        return m.value
      })
    },
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),

    confirmEditMembers(){
      const item = this.expanded[0]
     const mbrs = item.membersByMonth;     
     const mm = Number(moment().format('MM'))
     for (let index = mm; index < mbrs.length; index++) {
      mbrs[index]= Number(this.valuemembers);
     } 
   this.loading=true;
      getAPI
        .put(`/members/update/${item.uuid}`, {
          membersByMonth:mbrs
        })
        .then((res) => {
          notifySuccess(this.$t("Members has been updated"));
          this.cancelEditing()          
          this.getMembers()
          this.$emit('membersUpdated')
          this.dialogMembers=false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },


    toEditing(){
      this.editing=true;
      this.valuemembers=1
    },
    cancelEditing(){
      this.editing=false;    
      this.valuemembers=1
      this.loading = false;
    },
    getMembers() {
      const uuid = this.$route.params.uuid;
      this.loading = true;
      getAPI("/members/listByClient/" + uuid)
        .then((res) => {
          this.loading = false;
          this.members = res.data;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    openAddNumber() {
      const uuid = this.$route.params.uuid;
      this.$router.push("/healths/policy_create/" + uuid);
    },
    toEdit(item) {
      const uuid = this.$route.params.uuid;
      const st = JSON.stringify(item);
      const codif = btoa(st);
      localStorage.setItem("member", codif);
      this.$router.push("/healths/policy_edit/" + uuid);
    },

    toDelete(item) {
      this.deleteMember = {
        loading: false,
        dialog: true,
        item: item,
      };
    },
    confirmDelete() {
      this.deleteMember.loading = true;
      getAPI
        .put("/members/delete/" + this.deleteMember.item.uuid)
        .then((res) => {
          notifyInfo("Policy has been deleted");
          this.getMembers();
          this.$emit('membersUpdated')
          this.cancelAll();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.deleteMember.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    efectiveDate(effectiveDate) {
      if (!effectiveDate) {
        return "-";
      }

      const date = effectiveDate.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];
      const temp = `${m}-${d}-${y}`;
      const x = moment(temp).format("MMMM, YYYY");
      return x;
    },

    showMembers(item){
      if(item&&item.membersByMonth&&item.membersByMonth.length!=0){
        const m = Number (moment().format('MM'));
        return item.membersByMonth[m-1]
      }
      return 0
    },

    getLegal(value) {
      switch (value) {
        case "CITIZEN":
          return "Citizen";
        case "PERMANENT_RESIDENT":
          return "Permanent Resident";
        case "TEMPORAL_RESIDENT":
          return "Temporal Resident";

        default:
          return "-";
      }
    },
      colorPolicy(item) {
      const status = item.policyPlan;
      if (status != null && status != undefined) {
      switch (status) {
      case "GOLD":
      return "#EDC43D";
      case "SILVER":
      return "#C0C0C0";
      case "BRONZE":
      return "#CD7F32";
      
      default:
      return "#000";
      }
      }
      return "#000";
      },
      makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
      params: {
      phone: item,
      },
      }).then((res) => {
      window.open(res.data, "_blank", "noreferrer");
      });
      },
    getDate(value) {
      if (!value) {
        return "-";
      }

      const date = value.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];

      return `${m}/${d}/${y}`;
    },

    cancelAll() {
      this.deleteMember = {
        loading: false,
        dialog: false,
        item: null,
      };
    },
    async showDetails(patient) {
      await getAPI(`/patient/getPatient/${patient.uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
        this.$emit("detailsmemeber");
      });

      this.$router.push(`/healths/details/${patient.uuid}`);
    },

    getPatient() {
      getAPI(`/patient/getPatient/${this.$route.params.uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
      });
    },

    gotoMembers(item) {
      const arr = item.membersByMonth;
      if (arr == null || arr == undefined || arr.length == 0) {
        return;
      }
      this.itemMembers= item.membersByMonth;
      this.dialogMembers = true;
    },
  },
  async mounted() {
    this.getMembers();   
  },
};
</script>
<style lang="scss" scoped>
.valuemembers{
  width: 200px;
  margin-top: 30px;
  margin-right: 10px ;
}
</style>
